<template>
  <v-container :class="clienteComponent ? 'px-3' : ''">
    <v-card class="my-4">
      <Filtros
        P_status
        :P_statusArray="status"
        P_dataColuna
        P_prioridade
        P_dataFrom
        P_dataTo
        :P_clientes="clienteComponent ? false : true"
        P_colaborador
      />
    </v-card>
    <v-card :flat="clienteComponent ? true : false">
      <v-card-title
        v-if="!clienteComponent"
        :class="
          clienteComponent
            ? ' text-h5 font-weight-bold pa-3'
            : 'accent text-h5 font-weight-bold pa-3'
        "
      >
        <v-icon color="primary" left>mdi-toolbox-outline</v-icon>
        Atividades
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider v-if="!clienteComponent"></v-divider>
      <v-card-text>
        <v-card-title>
          <v-text-field
            style="max-width: 200px"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            clearable
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="white--text mr-6"
            :loading="loading"
            :disabled="!listaAtividades.length"
            @click="gerarpdf"
            depressed
          >
            relatório
          </v-btn>
          <v-btn
            @click="addAtividade"
            align="center"
            color="secondary"
            class="white--text"
          >
            adicionar
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaAtividades"
            :loading="loading"
            :items-per-page="10"
            :sort-desc="true"
            class="data-tables data-tables__row-click"
            @click:row="editAtividade"
            calculate-widths
          >
            <template v-slot:item.status="{ item }">
              <v-icon
                :color="
                  item.status == 0
                    ? 'grey'
                    : item.status == 1
                    ? 'blue-grey'
                    : item.status == 2
                    ? 'yellow darken-4'
                    : item.status == 3
                    ? 'green'
                    : item.status == 4
                    ? 'purple'
                    : item.status == 5
                    ? 'green darken-4'
                    : item.status == 6
                    ? 'deep-purple accent-4'
                    : item.status == -1
                    ? 'red'
                    : 'light-green'
                "
                small
                left
                >mdi-square-rounded</v-icon
              >
              <span class="subtitle-3">{{
                item.status | statusAtividade(item.entrega)
              }}</span>
            </template>
            <template v-slot:item.inicio="{ item }">
              <span class="subtitle-3">{{
                item.inicio | dateFormat("dd/MM/yyyy")
              }}</span>
            </template>
            <template v-slot:item.entrega="{ item }">
              <span class="subtitle-3">{{
                item.entrega | dateFormat("dd/MM/yyyy")
              }}</span>
            </template>
            <template v-slot:item.prioridade="{ item }">
              <div v-if="item.prioridade === 1">
                <v-icon color="prioridade_baixa"> mdi-flag </v-icon>
                Baixa
              </div>
              <div v-if="item.prioridade === 2">
                <v-icon color="prioridade_normal"> mdi-flag </v-icon>
                Normal
              </div>
              <div v-if="item.prioridade === 3">
                <v-icon color="prioridade_urgente"> mdi-flag </v-icon>
                Urgente
              </div>
              <div class="pa-0" v-if="item.prioridade === 4">
                <v-icon color="prioridade_emergencia"> mdi-flag </v-icon>
                Emergência
              </div>
            </template>
            <template v-slot:item.titulo="{ item }">
              <span class="subtitle-3">{{ item.titulo | excerpt(25) }}</span>
            </template>
            <template v-slot:item.cliente_nome_fantasia="{ item }">
              <span class="subtitle-3">{{
                item.cliente_nome_fantasia | excerpt(15)
              }}</span>
            </template>
            <template v-slot:item.cliente_servico="{ item }">
              <span class="subtitle-3">{{
                item.cliente_servico | excerpt(20)
              }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>
      <AtividadesNovoTrabalho
        v-if="dialogAddAtividade"
        :dialogAddAtividade.sync="dialogAddAtividade"
        @fetch-briefings="getAtividades"
        :clienteComponent="clienteComponent"
        :cliente_id="cliente_id"
      />
      <AtividadesEditarTrabalho
        v-if="dialogEditAtividade"
        :dialogEditAtividade.sync="dialogEditAtividade"
        :atividadeSelected="atividadeSelected"
        @fetch-briefings="getAtividades"
        :clienteComponent="clienteComponent"
        :cliente_id="cliente_id"
      />
      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        title="LISTA DE ATIVIDADES"
      />
    </v-card>
  </v-container>
</template>

<script>
import { fetchAtividadesClientes } from "@/api/gestor/atividades_clientes.js";
import { mapState } from "vuex";
import { fetchClientes } from "@/api/gestor/clientes.js";
const pdfAtividadesListaPdf = () =>
  import(/* webpackChunkName: "relatorio" */ "./reports/atividadesListaPdf");
export default {
  name: "ListaAtividades",

  props: {
    cliente_id: {
      type: [Number, String],
    },
    clienteComponent: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    PdfDialogComp: () => import("@/components/PdfDialogComp.vue"),
    AtividadesNovoTrabalho: () =>
      import("./components/AtividadesNovoTrabalho.vue"),
    AtividadesEditarTrabalho: () => import("./AtividadesEditarTrabalho.vue"),

    Filtros: () => import("@/components/filtros/Filtros.vue"),
  },

  data() {
    return {
      search: "",
      searchCliente: "",
      selectedCliente: null,
      dialogAddAtividade: false,
      dialogEditAtividade: false,
      loadingCliente: false,
      listaAtividades: [],
      edit: false,
      atividadeSelected: null,
      clientes: [],
      pdfData: null,
      loading: false,
      pdfDialog: false,
      menu: false,
      selectedStatus: null,
      status: [
        { id: -1, tipo: "CANCELADO" },
        { id: 0, tipo: "A FAZER" },
        { id: 8, tipo: "ATRASADAS" },
        { id: 1, tipo: "EM ANDAMENTO" },
        { id: 2, tipo: "EM ESPERA" },
        { id: 3, tipo: "CONCLUÍDO AGUARDANDO-GESTOR" },
        { id: 4, tipo: "REFAZER - GESTOR" },
        { id: 5, tipo: "APROVADO AGUARDANDO-CLIENTE" },
        { id: 6, tipo: "REFAZER - CLIENTE" },
        { id: 7, tipo: "FINALIZADO" },
      ],
    };
  },
  watch: {
    url() {
      this.getAtividades();
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "id",
          width: "70px",
          value: "id",
        },
        {
          text: "Titulo",
          width: "",
          value: "titulo",
        },
        {
          text: "Cliente",
          // width: "200px",
          value: "cliente_nome_fantasia",
        },
        {
          text: "Serviço",
          // width: "200px",
          value: "cliente_servico",
          sortable: false,
        },
        {
          text: "Inicio",
          // width: "140px",
          value: "inicio",
        },
        {
          text: "Entrega",
          // width: "140px",
          value: "entrega",
        },
        {
          text: "Status",
          width: "140px",
          value: "status",
          align: "start",
        },
        {
          text: "Prioridade",
          // width: "140px",
          value: "prioridade",
        },
      ];
    },
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      if (this.clienteComponent) {
        return `?cliente_id=${this.cliente_id}${queryString}`;
      } else {
        return `?user_id=${this.user_id}${queryString}`;
      }
    },
  },

  methods: {
    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfAtividadesListaPdf()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.listaAtividades).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
    getClientes() {
      this.loadingCliente = true;
      fetchClientes()
        .then((response) => {
          this.clientes = response;
          this.loadingCliente = false;
        })
        .catch(() => {
          this.loadingCliente = false;
        });
    },
    editAtividade(item) {
      this.atividadeSelected = item.id;

      this.dialogEditAtividade = true;
    },

    addAtividade() {
      this.dialogAddAtividade = true;
    },

    getAtividades() {
      this.loading = true;
      return fetchAtividadesClientes(`${this.url}`)
        .then((response) => {
          this.listaAtividades = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    if (this.cliente_id) {
      await this.getAtividades(`?cliente_id=${this.cliente_id}`);
    } else {
      await this.getClientes();
      await this.getAtividades();
    }
  },
};
</script>

<style lang="scss"></style>
