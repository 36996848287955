var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.clienteComponent ? 'px-3' : ''},[_c('v-card',{staticClass:"my-4"},[_c('Filtros',{attrs:{"P_status":"","P_statusArray":_vm.status,"P_dataColuna":"","P_prioridade":"","P_dataFrom":"","P_dataTo":"","P_clientes":_vm.clienteComponent ? false : true,"P_colaborador":""}})],1),_c('v-card',{attrs:{"flat":_vm.clienteComponent ? true : false}},[(!_vm.clienteComponent)?_c('v-card-title',{class:_vm.clienteComponent
          ? ' text-h5 font-weight-bold pa-3'
          : 'accent text-h5 font-weight-bold pa-3'},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-toolbox-outline")]),_vm._v(" Atividades "),_c('v-spacer')],1):_vm._e(),(!_vm.clienteComponent)?_c('v-divider'):_vm._e(),_c('v-card-text',[_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","clearable":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"white--text mr-6",attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.listaAtividades.length,"depressed":""},on:{"click":_vm.gerarpdf}},[_vm._v(" relatório ")]),_c('v-btn',{staticClass:"white--text",attrs:{"align":"center","color":"secondary"},on:{"click":_vm.addAtividade}},[_vm._v(" adicionar ")])],1),_c('v-divider'),_c('v-card-text',{},[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.listaAtividades,"loading":_vm.loading,"items-per-page":10,"sort-desc":true,"calculate-widths":""},on:{"click:row":_vm.editAtividade},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status == 0
                  ? 'grey'
                  : item.status == 1
                  ? 'blue-grey'
                  : item.status == 2
                  ? 'yellow darken-4'
                  : item.status == 3
                  ? 'green'
                  : item.status == 4
                  ? 'purple'
                  : item.status == 5
                  ? 'green darken-4'
                  : item.status == 6
                  ? 'deep-purple accent-4'
                  : item.status == -1
                  ? 'red'
                  : 'light-green',"small":"","left":""}},[_vm._v("mdi-square-rounded")]),_c('span',{staticClass:"subtitle-3"},[_vm._v(_vm._s(_vm._f("statusAtividade")(item.status,item.entrega)))])]}},{key:"item.inicio",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"subtitle-3"},[_vm._v(_vm._s(_vm._f("dateFormat")(item.inicio,"dd/MM/yyyy")))])]}},{key:"item.entrega",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"subtitle-3"},[_vm._v(_vm._s(_vm._f("dateFormat")(item.entrega,"dd/MM/yyyy")))])]}},{key:"item.prioridade",fn:function(ref){
                  var item = ref.item;
return [(item.prioridade === 1)?_c('div',[_c('v-icon',{attrs:{"color":"prioridade_baixa"}},[_vm._v(" mdi-flag ")]),_vm._v(" Baixa ")],1):_vm._e(),(item.prioridade === 2)?_c('div',[_c('v-icon',{attrs:{"color":"prioridade_normal"}},[_vm._v(" mdi-flag ")]),_vm._v(" Normal ")],1):_vm._e(),(item.prioridade === 3)?_c('div',[_c('v-icon',{attrs:{"color":"prioridade_urgente"}},[_vm._v(" mdi-flag ")]),_vm._v(" Urgente ")],1):_vm._e(),(item.prioridade === 4)?_c('div',{staticClass:"pa-0"},[_c('v-icon',{attrs:{"color":"prioridade_emergencia"}},[_vm._v(" mdi-flag ")]),_vm._v(" Emergência ")],1):_vm._e()]}},{key:"item.titulo",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"subtitle-3"},[_vm._v(_vm._s(_vm._f("excerpt")(item.titulo,25)))])]}},{key:"item.cliente_nome_fantasia",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"subtitle-3"},[_vm._v(_vm._s(_vm._f("excerpt")(item.cliente_nome_fantasia,15)))])]}},{key:"item.cliente_servico",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"subtitle-3"},[_vm._v(_vm._s(_vm._f("excerpt")(item.cliente_servico,20)))])]}}])})],1)],1),(_vm.dialogAddAtividade)?_c('AtividadesNovoTrabalho',{attrs:{"dialogAddAtividade":_vm.dialogAddAtividade,"clienteComponent":_vm.clienteComponent,"cliente_id":_vm.cliente_id},on:{"update:dialogAddAtividade":function($event){_vm.dialogAddAtividade=$event},"update:dialog-add-atividade":function($event){_vm.dialogAddAtividade=$event},"fetch-briefings":_vm.getAtividades}}):_vm._e(),(_vm.dialogEditAtividade)?_c('AtividadesEditarTrabalho',{attrs:{"dialogEditAtividade":_vm.dialogEditAtividade,"atividadeSelected":_vm.atividadeSelected,"clienteComponent":_vm.clienteComponent,"cliente_id":_vm.cliente_id},on:{"update:dialogEditAtividade":function($event){_vm.dialogEditAtividade=$event},"update:dialog-edit-atividade":function($event){_vm.dialogEditAtividade=$event},"fetch-briefings":_vm.getAtividades}}):_vm._e(),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":"LISTA DE ATIVIDADES"},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }